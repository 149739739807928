<template>
  <div>
    <b-list-group v-if="list.length > 0">
      <b-list-group-item v-for="(item, index) of list" :key="index">
        <b-badge variant="danger">{{ item.original }}</b-badge>
        <strong> -> </strong>
        <b-badge variant="success">{{ item.override }}</b-badge>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="no-remap text-center">
      <h6>No remap specified :(</h6>
      <small
        >When enable type remap you should have remap configured. Otherwise
        there will be <span class="text-danger">no options</span> type to
        select.</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.no-remap {
  width: 100%;
  height: auto;
  color: #555555;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}
</style>
