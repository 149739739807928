<template>
  <b-modal
    id="cms-create-remap-type-global"
    title="Create remap type global"
    no-close-on-backdrop
    size="md"
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <div class="col-12 text-right">
            <b-button
              variant="success"
              :disabled="!isEnableAdd"
              @click="onAddRemapInput"
              >+</b-button
            >
          </div>
        </div>
        <div
          v-for="(input, inputIndex) of cmsRemapTypeForm"
          :key="inputIndex"
          class="row align-items-end"
        >
          <div class="col-5">
            <ValidationProvider
              v-slot="{ errors }"
              name="original_type"
              :rules="{
                required: false,
              }"
            >
              <b-form-group label="Original type">
                <b-form-select
                  id="original_type"
                  v-model="input.original"
                  :state="errors[0] ? false : null"
                >
                  <b-form-select-option
                    v-for="(item, index) of availableTypes"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-5">
            <ValidationProvider
              v-slot="{ errors }"
              name="remap_type"
              :rules="{
                required: false,
              }"
            >
              <b-form-group label="Remap type">
                <b-form-select
                  id="remap_type"
                  v-model="input.override"
                  :state="errors[0] ? false : null"
                >
                  <b-form-select-option
                    v-for="(item, index) of availableTypes"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-2">
            <b-form-group>
              <b-button
                block
                variant="danger"
                @click="onRemoveRemapInput(inputIndex)"
                >-</b-button
              >
            </b-form-group>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    currentRemap: {
      type: Array,
      default: () => [],
    },
    typeKey: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      cmsRemapTypeForm: [
        {
          original: null,
          override: null,
        },
      ],
      initializeInput: {
        original: null,
        override: null,
      },
    }
  },
  computed: {
    ...mapState({
      cmsAllEnumTypes: (state) => state.cms.cmsAllEnumTypes,
      cmsEnumTypesRemapGlobal: (state) => state.cms.cmsEnumTypesRemapGlobal,
    }),
    availableTypes() {
      return this.typeKey ? this.cmsAllEnumTypes[this.typeKey] : []
    },
    isEnableAdd() {
      return this.cmsRemapTypeForm.length < this.availableTypes.length
    },
  },
  methods: {
    ...mapActions(['createRemapTypesGlobal']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-remap-type-global'))
      }
    },
    onAddRemapInput() {
      if (this.isEnableAdd) {
        this.cmsRemapTypeForm.push(
          JSON.parse(JSON.stringify(this.initializeInput))
        )
      }
    },
    onRemoveRemapInput(index) {
      this.cmsRemapTypeForm.splice(index, 1)
    },
    async onSubmit(cb) {
      const data = {
        ...this.cmsEnumTypesRemapGlobal,
        [this.typeKey]: this.cmsRemapTypeForm,
      }

      await this.createRemapTypesGlobal({
        ...data,
      })

      this.cmsRemapTypeForm = [this.initializeInput]

      cb()
    },
  },
}
</script>
