var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-remap-type-global","title":"Create remap type global","no-close-on-backdrop":"","size":"md"},on:{"ok":_vm.onOk}},[_c('ValidationObserver',{ref:"cmsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('b-button',{attrs:{"variant":"success","disabled":!_vm.isEnableAdd},on:{"click":_vm.onAddRemapInput}},[_vm._v("+")])],1)]),_vm._l((_vm.cmsRemapTypeForm),function(input,inputIndex){return _c('div',{key:inputIndex,staticClass:"row align-items-end"},[_c('div',{staticClass:"col-5"},[_c('ValidationProvider',{attrs:{"name":"original_type","rules":{
              required: false,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Original type"}},[_c('b-form-select',{attrs:{"id":"original_type","state":errors[0] ? false : null},model:{value:(input.original),callback:function ($$v) {_vm.$set(input, "original", $$v)},expression:"input.original"}},_vm._l((_vm.availableTypes),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)]}}],null,true)})],1),_c('div',{staticClass:"col-5"},[_c('ValidationProvider',{attrs:{"name":"remap_type","rules":{
              required: false,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remap type"}},[_c('b-form-select',{attrs:{"id":"remap_type","state":errors[0] ? false : null},model:{value:(input.override),callback:function ($$v) {_vm.$set(input, "override", $$v)},expression:"input.override"}},_vm._l((_vm.availableTypes),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)]}}],null,true)})],1),_c('div',{staticClass:"col-2"},[_c('b-form-group',[_c('b-button',{attrs:{"block":"","variant":"danger"},on:{"click":function($event){return _vm.onRemoveRemapInput(inputIndex)}}},[_vm._v("-")])],1)],1)])})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }