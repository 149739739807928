<template>
  <b-overlay :show="isFetchingRemapTypesGlobal">
    <b-card>
      <div class="row mb-2">
        <div class="col-6"><h5 class="text-primary">Type remap</h5></div>
        <div class="col-6 text-right">
          <b-button
            v-if="!isEnableRemapTypesGlobal"
            variant="success"
            @click="onEnableRemapTypeGlobal"
            >Enable remap</b-button
          >
          <b-button v-else variant="danger" @click="onDisabledRemapTypeGlobal"
            >Disable remap</b-button
          >
        </div>
      </div>
      <div v-if="isEnableRemapTypesGlobal" class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">
              <h6>Navs</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('navs')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('navs')"
                  >Add nav</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.navs" class="mb-3" />

          <div class="row">
            <div class="col-6">
              <h6>Top Menus</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('topMenus')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('topMenus')"
                  >Add top menus</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.topMenus" class="mb-3" />

          <div class="row">
            <div class="col-6">
              <h6>Footers</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('footers')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('footers')"
                  >Add footers</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.footers" class="mb-3" />

          <div class="row">
            <div class="col-6">
              <h6>Cards</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('cards')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('cards')"
                  >Add cards</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.cards" class="mb-3" />
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">
              <h6>Titles</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('titles')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('titles')"
                  >Add titles</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.titles" class="mb-3" />

          <div class="row">
            <div class="col-6">
              <h6>Floating Menus</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('floatingMenus')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('floatingMenus')"
                  >Add flating menus</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList
            :list="cmsEnumTypesRemapGlobal.floatingMenus"
            class="mb-3"
          />

          <div class="row">
            <div class="col-6">
              <h6>Banners</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('banners')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('banners')"
                  >Add banners</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.banners" class="mb-3" />

          <div class="row">
            <div class="col-6">
              <h6>Dashboards</h6>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button
                  variant="info"
                  size="sm"
                  @click="onUseDefaultRemap('dashboards')"
                  >Use default</b-button
                >
                <b-button
                  variant="success"
                  size="sm"
                  @click="onAddRemap('dashboards')"
                  >Add dashboards</b-button
                >
              </b-button-group>
            </div>
          </div>
          <RemapList :list="cmsEnumTypesRemapGlobal.dashboards" class="mb-3" />
        </div>
      </div>
    </b-card>
    <CreateRemapTypeGlobal :current-remap="currentRemap" :type-key="typeKey" />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RemapList from './type-remap/components/remap-list.vue'
import CreateRemapTypeGlobal from './type-remap/create-remap-type-global.vue'
export default {
  components: {
    RemapList,
    CreateRemapTypeGlobal,
  },
  data() {
    return {
      remapData: null,
      typeKey: null,
      currentRemap: null,
    }
  },
  computed: {
    ...mapState({
      isFetchingRemapTypesGlobal: (state) =>
        state.cms.isFetchingRemapTypesGlobal,
      cmsEnumTypesRemapGlobal: (state) => state.cms.cmsEnumTypesRemapGlobal,
      cmsAllEnumTypes: (state) => state.cms.cmsAllEnumTypes,
    }),
    ...mapGetters(['isEnableRemapTypesGlobal']),
  },
  watch: {
    cmsEnumTypesRemapGlobal(val) {
      if (val) {
        this.remapData = JSON.parse(JSON.stringify(val))
      }
    },
  },
  mounted() {
    this.fetchRemapTypesGlobal()
    this.fetchAllEnumTypes()
  },
  methods: {
    ...mapActions([
      'fetchRemapTypesGlobal',
      'createRemapTypesGlobal',
      'fetchAllEnumTypes',
      'removeRemapTypesGlobal',
    ]),
    onEnableRemapTypeGlobal() {
      this.createRemapTypesGlobal()
    },
    onDisabledRemapTypeGlobal() {
      this.removeRemapTypesGlobal()
    },
    async onUseDefaultRemap(typeKey) {
      this.$bvModal
        .msgBoxConfirm(`This will override all ${typeKey} to default. OK?`)
        .then(async (value) => {
          if (value) {
            const defaults = this.cmsAllEnumTypes[typeKey].map((tc) => ({
              original: tc,
              override: tc,
            }))

            const data = {
              ...this.cmsEnumTypesRemapGlobal,
              [typeKey]: defaults,
            }

            await this.createRemapTypesGlobal({
              ...data,
            })
          }
        })
    },
    onAddRemap(typeKey) {
      this.typeKey = typeKey
      this.currentRemap = this.remapData[typeKey]
      this.$bvModal.show('cms-create-remap-type-global')
    },
  },
}
</script>
